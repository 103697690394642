.header {
  width: 100%;
  height: 90px;
  position: fixed;
  z-index: 100;
  top:0;
  background: #3fc0fa;
@include desktops{
  height: 75px;
}
  @include laptops{
    height: 60px;
  }
@include hdlaptopsdeg{
  height: 60px;
}
  @include tablets{
    background: #31bbf9;
  }
}
.header_inn {
  width: 900px;
  margin: 0 auto;
  position: relative;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include tablets{
    width: 90%;
  }
}

.menu-home {
  display: flex;
  @include tablets{
    display: none;
  }
}

.menu-home__item {
  list-style-type: none;
  margin: 0 10px;
}
.menu-home__active{
  border-radius: 25px;
  -webkit-box-shadow: 0px 0px 5px 1px rgba(255,255,255,0.40);
  -moz-box-shadow: 0px 0px 5px 1px rgba(255,255,255,0.40);
  box-shadow: 0px 0px 5px 1px rgba(255,255,255,0.40);
  & a{
    border-radius: 25px;
    padding: 10px 20px;
    display: inline-block;
    -webkit-box-shadow: inset 0px 0px 5px 1px rgba(0,120,172,1);
    -moz-box-shadow: inset 0px 0px 5px 1px rgba(0,120,172,1);
    box-shadow: inset 0px 0px 5px 1px rgba(0,120,172,1);
    @include tablets{
      box-shadow: none;
    }
  }
  @include tablets{
    box-shadow: none;
  }
}
.menu-home__link {
  font-size: 19px;
  display: inline-block;
  border-radius: 25px;
  padding: 10px 20px;
  text-decoration: none;
  text-shadow: 0px -1px 2px rgba(0, 88, 153, 1);
  &:hover{
    color: #003e78;
    color: #c0fa00;
    color: #ffe718;
  }
@include desktops{
  font-size: 16px;
}
  @include hdlaptopsdeg{
    font-size: 14px;
  }


}
//.page-articles{
//  & .menu-home__item{
//    &:nth-child(2){
//      border-radius: 25px;
//      -webkit-box-shadow: 0px 0px 5px 1px rgba(255,255,255,0.40);
//      -moz-box-shadow: 0px 0px 5px 1px rgba(255,255,255,0.40);
//      box-shadow: 0px 0px 5px 1px rgba(255,255,255,0.40);
//      & a{
//        border-radius: 25px;
//        padding: 10px 20px;
//        display: inline-block;
//        -webkit-box-shadow: inset 0px 0px 5px 1px rgba(0,120,172,1);
//        -moz-box-shadow: inset 0px 0px 5px 1px rgba(0,120,172,1);
//        box-shadow: inset 0px 0px 5px 1px rgba(0,120,172,1);
//      }
//    }
//  }
//  & .menu-home__active{
//    -webkit-box-shadow: 0px 0px 0px 0px rgba(255,255,255,0);
//    -moz-box-shadow: 0px 0px 0px 0px rgba(255,255,255,0);
//    box-shadow: 0px 0px 0px 0px rgba(255,255,255,0);
//    & a{
//      -webkit-box-shadow: inset 0px 0px 0px 0px rgba(0,120,172,0);
//      -moz-box-shadow: inset 0px 0px 0px 0px rgba(0,120,172,0);
//      box-shadow: inset 0px 0px 0px 0px rgba(0,120,172,0);
//    }
//  }
//}


.logo_icon {
  background-image: url("/assets/img/biglogo.png");
  background-position: 0px 0px;
  width: 179px;
  height: 47px;
  background-size: contain;
  background-repeat: no-repeat;
@include laptops{
  width: 143px;
  height:37px;
}
  @include hdlaptopsdeg{
    width: 143px;
    height:37px;
  }
}

.menu-lang {
  list-style-type: none;
  & .lang-item {
    float: left;
    padding: 0 5px;
    & a {
      text-decoration: none;
      text-shadow: 0rem -0.0625rem 0.125rem #005899;
      &:hover{
        color: #003e78;
        color: #c0fa00;
        color: #ffe718;
      }
    }
    &.lang-item-active a{
      color: #003e78;
      color: #c0fa00;
      color: #ffe718;
    }

  }
  &.active{
    display: block;
    z-index: 100;
    left: 0;
    top: 40%;
    position: absolute;
    animation: fadeInUp 2s ease-in-out forwards 0.6s;
  }
  @include hdlaptopsdeg{
    font-size: 14px;
  }
  @include tablets{
    display: none;
    opacity: 0;
  }
}

.menu-hamburger {
  position: absolute;
  z-index: 30;
  right: 30px;
  top: 28px;
  width: 35px;
  height: 30px;
  cursor: pointer;
  display: none;
  &:hover {
    .menu-hamburger__item, .menu-hamburger__item:before, .menu-hamburger__item:after {
      //background: #004cd1;
    }
  }
  @include tablets{
    display: block;
    z-index: 10;
  }
}

.menu-hamburger_mob {
  background: #29b8f9;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: -100vh;
  left: 0;
  z-index: 0;
  transition: 1s;
  &.active {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 0;
    animation: fadeInDownNOP 1s ease-in-out forwards;
  }
}

.menu-home_active {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  text-align: center;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 90%;
  height: 50%;
  z-index: 30;
  opacity: 0;
  animation: fadeIn 2s ease-in-out forwards 0.6s;
  & .menu-home__link{
    font-size: 20px;
  }
}


